import React from "react";
import { colorLibrary } from "../helpers/common";
import { ClipLoader } from "react-spinners";

const loadingOuter = {
  alignSelf: "center",
};

const Loading = () => {
  return (
    <div style={loadingOuter}>
      <ClipLoader color={colorLibrary.licorice} loading={true} size={50} />
    </div>
  );
};

export default Loading;
