import React, { ReactNode, useContext, useEffect } from "react";
import { colorLibrary, locationPathnameIfDefined } from "../helpers/common";
import { Link, navigate, withPrefix } from "gatsby";
import { AuthenticationContext } from "../providers/AuthenticationProvider";
import Loading from "./Loading";

const navOuter = {
  position: "absolute" as const,
  right: 0,
  display: "flex",
  flexDirection: "row" as const,
  zIndex: 1,
};
const contentOuter = {
  width: "100%",
  height: "85vh",
  display: "flex",
  flexDirection: "row" as const,
};

const tabOuter = {
  height: "30px",
  display: "flex",
  flexDirection: "row" as const,
  justifyContent: "flex-start",
  alignItems: "center",
};
const tabStyle = {
  padding: "5px 10px",
  textAlign: "center" as const,
  cursor: "pointer",
  backgroundColor: colorLibrary.ashGrey,
};
const selectedTabStyle = {
  backgroundColor: colorLibrary.orange,
};

type navType = "phonereps" | "chatreps" | "admin" | "emailreps" | "blogreps";

const toTitleCase = (str: string) => {
  return str.replace(/\w\S*/g, (txt) => {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};

const tabNames: { [K in navType]: string } = {
  phonereps: "PhoneReps",
  chatreps: "ChatReps",
  admin: "Admin",
  emailreps: "EmailReps",
  blogreps: "AI Blog Posts",
};

const center = {
  justifyContent: "center" as const,
};

const getTabs = (isAdmin: boolean): Array<navType> => {
  if (isAdmin) {
    return ["chatreps", "emailreps", "phonereps", "blogreps", "admin"];
  }
  return ["chatreps", "blogreps", "admin"];
};

export const ContentOuter = ({ children }: { children: ReactNode }) => {
  const isRoot = locationPathnameIfDefined() === withPrefix("/");
  const { loading, user } = useContext(AuthenticationContext);
  const { is_admin } = user ? user : { is_admin: false };

  const pathSplit = locationPathnameIfDefined().split("/");
  const selectedTab =
    pathSplit.length > 1 ? (pathSplit[1] as navType) : ("chatreps" as navType);
  const tabs = getTabs(is_admin);

  useEffect(() => {
    if (isRoot) {
      navigate("/phonereps");
    }
  }, [isRoot]);

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          width: "100%",
          height: "100%",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <Loading />
      </div>
    );
  }

  const isKnownPath = tabs.includes(selectedTab);

  if (!isKnownPath) {
    return <div style={{ ...contentOuter, ...center }}>{children}</div>;
  }

  return <div style={contentOuter}>{children}</div>;
};

const Navigation = () => {
  const { logout, authenticated, user } = useContext(AuthenticationContext);
  const { is_admin } = user ? user : { is_admin: false };

  const pathSplit = locationPathnameIfDefined().split("/");
  const selectedTab =
    pathSplit.length > 1 ? (pathSplit[1] as navType) : ("chatreps" as navType);

  const tabs = getTabs(is_admin);

  if (!authenticated) {
    return null;
  }

  return (
    <div style={navOuter}>
      <div style={tabOuter}>
        {tabs.map((tab) => {
          return (
            <Link
              key={tab}
              to={`/${tab}`}
              style={{
                ...tabStyle,
                ...(selectedTab === tab ? selectedTabStyle : {}),
              }}
            >
              {tabNames[tab] || toTitleCase(tab)}
            </Link>
          );
        })}
        <div
          style={{
            ...tabStyle,
            cursor: "pointer",
          }}
          onClick={logout}
        >
          Log Out
        </div>
      </div>
    </div>
  );
};

export default Navigation;
