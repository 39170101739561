export const colorLibrary = {
  licorice: "#c6c6c6",
  orange: "#f8bf58",
  ashGrey: "#ededed",
  frenchGrey: "#847A8A",
  platinum: "#85738A",
};

export const hrStyle = {
  width: "100%",
  outline: "none",
  borderTop: "none",
  borderLeft: "none",
  borderRight: "none",
  borderBottom: `2px solid ${colorLibrary.orange}`,
  margin: "20px 0",
};

export const colorWithOpacity = (hexCode: string, opacity = 1) => {
  let hex = hexCode.replace("#", "");

  if (hex.length === 3) {
    hex = `${hex[0]}${hex[0]}${hex[1]}${hex[1]}${hex[2]}${hex[2]}`;
  }

  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  /* Backward compatibility for whole number based opacity values. */
  if (opacity > 1 && opacity <= 100) {
    opacity = opacity / 100;
  }

  return `rgba(${r},${g},${b},${opacity})`;
};

export const locationPathnameIfDefined = () => {
  if (typeof window !== "undefined") {
    return location.pathname;
  }
  return "";
};

export const getQueryParamIfAvailable = (param: string) => {
  if (typeof window !== "undefined") {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get(param) || "";
  }
  return "";
};

export const setQueryParamIfAvailable = (param: string, value: string) => {
  if (typeof window !== "undefined") {
    const urlParams = new URLSearchParams(window.location.search);
    urlParams.set(param, value);
    window.history.replaceState({}, "", `?${urlParams.toString()}`);
  }
};

export const escapeCSVField = (field: string) => {
  return `${field.replace(/"/g, "'").replace(/\n/g, " ")}`;
};
