import axios from "axios";
import React, {
  ReactElement,
  createContext,
  useCallback,
  useEffect,
  useState,
} from "react";
import { localStorageTokenKey } from "../helpers/axios";
import { navigate } from "gatsby";
import { User, VREPClient } from "../../../src/types/api";

type AuthenticationContextType = {
  authenticated: boolean;
  loading: boolean;
  attemptLogin: (email: string, password: string) => Promise<void>;
  logout: () => void;
  user: User | null;
  client: VREPClient | null;
  handleLogin: (token: string, user: User, client: VREPClient) => void;
};

export const AuthenticationContext = createContext<AuthenticationContextType>({
  authenticated: false,
  loading: false,
  attemptLogin: () => {
    return Promise.resolve();
  },
  logout: () => {
    return;
  },
  user: null,
  client: null,
  handleLogin: () => {
    return;
  },
});

const clearFromLocalStorage = () => {
  localStorage.removeItem(localStorageTokenKey);
};

const setInLocalStorage = (token: string) => {
  localStorage.setItem(localStorageTokenKey, token);
};

const getFromLocalStorage = () => {
  return localStorage.getItem(localStorageTokenKey);
};

export const AuthenticationProvider = ({
  children,
}: {
  children: ReactElement;
}) => {
  const [user, setUser] = useState<User | null>(null);
  const [client, setClient] = useState<VREPClient | null>(null);
  const [authenticated, setAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);

  const checkToken = useCallback(async (token: string) => {
    try {
      const res = await axios.post(
        `${process.env.GATSBY_API_URL}/api/auth/validate-token`,
        null,
        {
          headers: {
            "Auth-Token": token,
          },
        }
      );
      if (res.data.authenticated) {
        setAuthenticated(true);
        setInLocalStorage(token);
        setUser(res.data.user);
        setClient(res.data.client);
      } else {
        alert("Error authenticating");
        setAuthenticated(false);
        clearFromLocalStorage();
      }
    } catch (e) {
      alert("Error authenticating");
      console.error(e);
    }
    setLoading(false);
  }, []);

  const handleLogin = useCallback(
    async (token: string, user: User, client: VREPClient) => {
      setAuthenticated(true);
      setInLocalStorage(token);
      setUser(user);
      setClient(client);
      navigate("/chatreps");
    },
    []
  );

  const attemptLogin = useCallback(
    async (email: string, password: string) => {
      try {
        const res = await axios.post(
          `${process.env.GATSBY_API_URL}/api/auth/login`,
          { email: email.toLowerCase(), password }
        );
        if (res.data.token) {
          handleLogin(res.data.token, res.data.user, res.data.client);
        } else {
          alert("Error authenticating");
        }
      } catch (e) {
        alert("Error authenticating");
        console.error(e);
      }
    },
    [handleLogin]
  );

  const attemptFetchFromLocalStorage = useCallback(() => {
    const token = getFromLocalStorage();
    if (token) {
      checkToken(token);
    } else {
      setLoading(false);
    }
  }, [checkToken]);

  // todo: this effect is getting run twice
  useEffect(() => {
    if (!authenticated) {
      attemptFetchFromLocalStorage();
    }
  }, [attemptFetchFromLocalStorage, authenticated]);

  const logout = useCallback(async () => {
    const confirmed = window.confirm("Are you sure you want to log out?");
    if (!confirmed) return;
    navigate("/login");
    clearFromLocalStorage();
    setAuthenticated(false);
    setUser(null);
  }, []);

  return (
    <AuthenticationContext.Provider
      value={{
        authenticated,
        attemptLogin,
        loading,
        user,
        logout,
        client,
        handleLogin,
      }}
    >
      {children}
    </AuthenticationContext.Provider>
  );
};
