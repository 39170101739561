import React from "react";
import { AuthenticationProvider } from "./src/providers/AuthenticationProvider";
import Navigation, { ContentOuter } from "./src/components/navigation";
import "./src/styles/global.css";
import { colorLibrary } from "./src/helpers/common";
import { VrepLogo } from "./src/components/Logo";

export const wrapRootElement = ({ element }) => {
  return <AuthenticationProvider>{element}</AuthenticationProvider>;
};

const header = {
  width: "100vw",
  display: "flex",
  flexDirection: "row" as const,
  justifyContent: "center",
  borderBottom: `2px solid ${colorLibrary.ashGrey}`,
};

const pageStyles = {
  color: "#232129",
  fontFamily: "-apple-system, Roboto, sans-serif, serif",
  height: "100vh",
};

export const wrapPageElement = ({ element, props }) => {
  return (
    <main style={pageStyles}>
      <div className="header" style={header}>
        <Navigation {...props} />
        <VrepLogo />
      </div>
      <ContentOuter>{element}</ContentOuter>
    </main>
  );
};
