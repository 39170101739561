import axios from "axios";

export const localStorageTokenKey = "saleshive.ai.authToken";

const authenticatedAxios = axios.create();

authenticatedAxios.interceptors.request.use((config) => {
  config.headers["Auth-Token"] = localStorage.getItem(localStorageTokenKey);
  return config;
});

authenticatedAxios.interceptors.response.use(
  (response) => {
    return response;
  },
  function (error) {
    if (error.response.status === 401) {
      localStorage.removeItem(localStorageTokenKey);
      window.location.href = "/login";
    }

    return error;
  }
);

export default authenticatedAxios;
