import React from "react";
import { StaticImage } from "gatsby-plugin-image";

const headImage = {
  height: "80px",
  objectFit: "contain" as const,
  margin: "auto",
};
const headImageOuter = {
  height: "120px",
};

export function VrepLogo() {
  return (
    <StaticImage
      style={headImageOuter}
      imgStyle={headImage}
      src="../images/vrep_logo.png"
      alt=""
    />
  );
}
